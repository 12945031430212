<template>
  <div class="col-12 p-0 text-center mt-4">
    <div class="row m-0 justify-content-center">
      <div class="col-auto px-2">
        <IconRound align="center" class="cursor" bg="transparent" @click="facebook">
          <logo-facebook-icon />
        </IconRound>
      </div>
      <div class="col-auto px-2">
        <IconRound align="center" class="cursor" bg="transparent" @click="twitter">
          <logo-twitter-icon />
        </IconRound>
      </div>
      <div class="col-auto px-2">
        <IconRound align="center" class="cursor" bg="transparent" @click="instagram">
          <logo-instagram-icon />
        </IconRound>
      </div>
      <div class="col-auto px-2">
        <IconRound align="center" class="cursor" bg="transparent" @click="youtube">
          <logo-youtube-icon />
        </IconRound>
      </div>
    </div>
  </div>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
  components: {
    IconRound: defineAsyncComponent(() => import('@/components/IconRound.vue')),
    'logo-facebook-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-facebook.vue')),
    'logo-twitter-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-twitter.vue')),
    'logo-instagram-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-instagram.vue')),
    'logo-youtube-icon': defineAsyncComponent(() => import('vue-ionicons/dist/logo-youtube.vue'))
  },
  name: 'SocialMediaLinks',
  methods: {
    facebook () {
      if (this.isUK) {
        window.open('https://en-gb.facebook.com/AlImdaadFoundationUK/', '_blank')
      } else {
        window.open('https://web.facebook.com/alimdaadhq', '_blank')
      }
    },
    twitter () {
      if (this.isUK) {
        window.open('https://twitter.com/Alimdaad_UK', '_blank')
      } else {
        window.open('https://twitter.com/Alimdaad', '_blank')
      }
    },
    instagram () {
      if (this.isUK) {
        window.open('https://www.instagram.com/alimdaadfoundationuk/', '_blank')
      } else {
        window.open('https://www.instagram.com/alimdaad/', '_blank')
      }
    },
    youtube () {
      if (this.isUK) {
        window.open('https://www.youtube.com/user/ALIMDAAD', '_blank')
      } else {
        window.open('https://www.youtube.com/user/ALIMDAAD', '_blank')
      }
    }
  },
  computed: {
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
